<template>
  <page-view :title="title" left-arrow :nav-border="false" custom-class="dfzwPage">
    <template #nav-right>
      <yhbh-select v-model="yhbh" @confirm="yhbhChangeHandle" style="margin-right: -16px;" />
    </template>

    <loading-layout ref="loadingLayoutOuter" error-text="绑定户号信息查询失败" empty-text="未绑定用户编号">
      <template #empty>
        <yhbh-empty fromPage="yhzd-query" />
      </template>

      <div class="top">
        <!-- 个人 -->
        <van-cell-group v-if="yhJbxx.nsbz == '0'" :border="false">
          <van-cell title="纳税标志：" :value="yhJbxx.nsbz | zfdmFilter(nsbzZfdmList)" />
          <van-cell title="户号：" :value="yhJbxx.yhbh" />
          <van-cell title="户名：" :value="yhJbxx.yhmc" />
          <van-cell title="发票抬头：" :value="yhJbxx.yhmc" />
          <van-cell title="证件编号：" :value="yhJbxx.zjbh" />
          <van-cell title="电子邮箱：" :value="yhJbxx.email" />
        </van-cell-group>
        <!-- 企业 -->
        <van-cell-group v-else :border="false">
          <van-cell title="纳税标志：" :value="yhJbxx.nsbz | zfdmFilter(nsbzZfdmList)" />
          <van-cell title="户号：" :value="yhJbxx.yhbh" />
          <van-cell title="户名：" :value="yhJbxx.yhmc" />
          <van-cell title="发票抬头：" :value="yhNsxx.nsrmc" />
          <van-cell title="纳税识别号：" :value="yhNsxx.nsrsbh" />
          <van-cell title="联系电话：" :value="yhNsxx.nsrdh" />
        </van-cell-group>
      </div>

      <van-tabs v-model="activeTabIndex" scrollspy sticky offset-top="44" title-active-color="#00b1ff" color="#00b1ff">
          <van-list class="dzfp-list" v-model="listLoading" :finished="listFinished" finished-text="没有更多了" :error.sync="listLoadError" error-text="加载失败，点击重试"
            @load="fetchDzfpList">
        <van-tab v-for="(item,index) in list" :title="item.nf+ ' 年'" :key="index">
            <div class="dzfp-item" v-for="(child,idx) in item.children" :key="idx">
              <div class="dzfp-item--header">
                <van-tag type="primary" class="zwyf mt-15">{{child.zwyf}}</van-tag>
                <van-tag type="warning" v-if="yhJbxx.tyjsh && yhJbxx.tyjsh != '0' ? true: false">合打</van-tag>
              </div>
              <van-cell-group class="dzfp-item--content">
                <van-cell :border="false" title="总电量：">{{child.dl}}</van-cell>
                <van-cell :border="false" title="总电费：">{{child.ysdf | currency}}</van-cell>
                <van-cell :border="false" title="已开票金额：">{{child.ykdf | currency}}</van-cell>
                <van-cell :border="false" title="未开票金额：">{{child.wkdf | currency}}</van-cell>
              </van-cell-group>
              <div class="dzfp-item--actions">
                <van-button v-if="child.pdfurl" type="info" size="small" @click="handleViewPdf(child)">查看发票</van-button>
                <van-button type="primary" size="small" @click="handleInvoiceOpen(child)">开票</van-button>
              </div>
            </div>
        </van-tab>
          </van-list>
      </van-tabs>

    </loading-layout>

    <van-action-sheet v-model="dialogVisible" title="确认开票">
      <van-cell-group v-if="yhJbxx.nsbz == '0'" title="发票抬头">
        <van-cell title="户号：" :value="yhJbxx.yhbh" :border="false" />
        <van-cell title="户名：" :value="yhJbxx.yhmc" :border="false" />
        <van-cell title="证件编号：" :value="yhJbxx.zjbh" :border="false" />
        <van-cell title="手机号：" :value="yhJbxx.lxsj" :border="false" />
      </van-cell-group>
      <van-cell-group v-else title="发票抬头">
        <van-cell title="户号：" :value="yhJbxx.yhbh" :border="false" />
        <van-cell title="户名：" :value="yhJbxx.yhmc" :border="false" />
        <van-cell title="发票抬头：" :value="yhNsxx.nsrmc" :border="false" />
        <van-cell title="纳税标志：" :value="yhJbxx.nsbz | zfdmFilter(nsbzZfdmList)" :border="false" />
        <van-cell title="纳税人识别号：" :value="yhNsxx.nsrsbh" :border="false" />
        <van-cell title="地址、电话：" :value="yhNsxx.nsrdz + ' ' + yhNsxx.nsrdh" :border="false" />
        <van-cell title="开户行及账号：" :value="yhNsxx.nsrkhh+' ' +yhNsxx.nsryhzh" :border="false" />
      </van-cell-group>
      <van-cell-group title="开票信息" :border="false">
        <van-cell :border="false" title="账务月份：" :value="selectData.zwyf" />
        <van-cell :border="false" title="电费：" :value="selectData.ysdf | currency" />
        <van-cell :border="false" title="开票金额：" :value="selectData.wkdf | currency" />
      </van-cell-group>
      <div style="margin: 16px; margin-top: 32px;">
        <van-button block type="primary" native-type="submit" :disabled="invoiceOpening" @click="invoiceOpenConfirm">确认开票
        </van-button>
      </div>
    </van-action-sheet>
  </page-view>
</template>

<script>
  import {
    Tab,
    Tabs
  } from 'vant';
  import YhbhSelect from '@/components/YhbhSelect'
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import {
    yhJbxx_fetchOneYhJbxxByYhbh,
    yhNsxx_selectiveGetOne
  } from '@/api/zhcx';
  import {
    zwLjfysmx_getDzfpByYhbh,
    invoiceOpen
  } from '../../../api/invoice';
  import {
    zfdmManageService_getZfdmByDmzl
  } from '@/api/zfdm'
  export default {
    name: 'Dzfp',
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      YhbhSelect,
      YhbhEmpty
    },
    data() {
      return {
        title: '电子发票',
        yhbh: '',
        yhJbxx: {},
        yhNsxx: {},
        year: '',
        sort: '',
        list: [],
        loadedList: [],
        listLoading: false,
        listFinished: false,
        listLoadError: false,
        pageNum: 0,
        pageSize: 5,
        selectData: {},
        dialogVisible: false,
        invoiceOpening: false,
        nsbzZfdmList: [],
        activeTabIndex: 0
      }
    },
    methods: {
      fetchYhJbxx() {
        this.$toast.loading('加载中...')
        yhJbxx_fetchOneYhJbxxByYhbh(this.yhbh).then(res => {
          this.yhJbxx = res.content;
          if (this.yhJbxx.nsbz != '0') {
            this.fetchYhNsxx();
          }
          // 获取发票列表
          
          this.fetchDzfpList(true);
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          this.$toast.clear();
        });
      },
      fetchYhNsxx() {
        this.$toast.loading('加载中...')
        yhNsxx_selectiveGetOne({
          yhbh: this.yhbh
        }).then(res => {
          if (res.content) {
            this.yhNsxx = res.content;
          } else {
            throw new Error("无纳税信息!")
          }
          this.$toast.clear();
        }).catch(err => {
          console.log(err);
          this.$toast.clear();
        });
      },
      fetchDzfpList(isRefresh = false) {
      
        if (isRefresh) {
          this.pageNum = 0;
          this.listFinished = false;
          this.loadedList= false;
          this.loadedList = [];
          this.list = [];
        }

        let pagination = {
          pageNum: this.pageNum == 0? 1 : this.pageNum +1,
          pageSize: this.pageSize
        }
        this.listLoading = true;
        zwLjfysmx_getDzfpByYhbh(this.yhbh, pagination).then(res => {
          if (res.code == 200) {
            this.loadedList = this.loadedList.concat(res.content);
            this.pageNum = res.pagination.pageNum;
            // 数据预处理
            let tmpObj = {};
            let tmpArr = [];
            for (let [i, v] of  this.loadedList.entries()) {
              if (tmpObj[v.nf]) {
                tmpObj[v.nf].push(v);
              } else {
                tmpObj[v.nf] = [v];
              }
            }
            for (let key of Object.keys(tmpObj)) {
              tmpArr.push({
                nf: key,
                children: tmpObj[key]
              });
            }
            tmpArr.sort(function (a, b) {
              return b.nf - a.nf;
            });
            this.list = tmpArr;
            if ( this.loadedList.length >= res.pagination.total) {
              this.listFinished = true;
            }
          } else {
            this.listLoadError = true;
          }
          this.listLoading = false;
        }).catch(err => {
          console.log(err);
          this.$toast(err.message);
          this.listLoading = false;
          this.listLoadError = true
        })
      },
      yhbhChangeHandle(jbxx) {
        this.yhbh = jbxx.yhbh;
        // 获取用户基本信息
        this.fetchYhJbxx();
      },
      // 开票
      handleInvoiceOpen(item) {
        if (this.yhJbxx.nsbz != '0' && !this.yhNsxx.nsrsbh ) {
           this.$dialog.alert({
            title: "提示",
            message: "纳税信息未维护，无法开票，请先到营业厅维护纳税信息。"
          });
        } else if (item.wkdf > 0) {
          this.selectData = item;
          this.dialogVisible = true;
        } else {
          this.$dialog.alert({
            title: "提示",
            message: "未开票金额为0， 无法开具电子发票！"
          });
        }
      },
      // 开票确认
      invoiceOpenConfirm() {
        const {
          yhbh,
          zwyf
        } = this.selectData;
        if (yhbh && zwyf) {
          this.invoiceOpening = true;
          let loading = this.$toast.loading({
            message: "正在开票",
            forbidClick: true,
            duration: 0
          })
          invoiceOpen({
            yhbh,
            zwyf
          }).then(response => {
            let resp = JSON.parse(response);
            if(resp.code == "9999") {
              this.dialogVisible = false;
              this.selectData = {};
              this.$toast.success("开票成功")
              this.fetchDzfpList(true); // 刷新列表
            } else {
              this.$dialog.alert({
                title: "开票失败",
                message: `${resp.message}(错误码：${resp.code})`,
                messageAlign: "left"
              });
            }
          }).catch(err => {
            this.$toast("开票失败:", err.message)
          }).finally(() => {
            this.invoiceOpening = false;
            loading.clear();
          })
        }
      },
      // 查看电子发票
      handleViewPdf(item) {
        if (item.pdfurl) {
          let filename = `${item.yhbh}_${item.zwyf}.pdf`;
          this.$router.push({
            path: '/pdf-viewer', 
            query:{
              pdfurl: item.pdfurl + '?'+filename
            }
          });
        } else {
          this.$toast("发票地址为空")
        }
      },
      onSubmit() {}
    },
    mounted() {
      this.$refs['loadingLayoutOuter'].setStatus('loading');
      this.$store.dispatch('getYhbhList').then(list => {
        this.yhbhList = list;
        if (list.length == 0) {
          this.$refs['loadingLayoutOuter'].setStatus('empty');
        } else {
          this.$refs['loadingLayoutOuter'].setStatus('success');
          this.yhbh = list[0].yhbh;
          this.yhbhChangeHandle(list[0]);
        }
      }).catch(err => {
        this.$refs['loadingLayoutOuter'].setStatus('error');
      });

      // 获取纳税标志字符代码
      zfdmManageService_getZfdmByDmzl('NSBZ').then(response => {
        this.nsbzZfdmList = response.content;
      });
    },
  }
</script>

<style lang="scss" scoped>
  $top-bg-color: #00b1ff;

  .dfzwPage /deep/ {
    .van-nav-bar {
      background-color: $top-bg-color;
      color: #fff;
    }

    .van-nav-bar .van-icon,
    .van-nav-bar__title {
      color: #fff;
    }

    .top {
      background-color: $top-bg-color;
      position: relative;
      padding: 16px 32px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/static/img/city-shadow.11e155ae.png);
        background-position: bottom;
        background-size: auto 90%;
        background-repeat: no-repeat;
        opacity: 0.13;
      }

      .van-cell-group,
      .van-cell {
        background-color: transparent;
      }

      .van-cell {
        color: #fff;
        padding: 0 16px;
        line-height: 28px;

        &::after {
          border: 0;
        }
      }

      .van-cell__title {
        flex: 0 0 auto;
      }

      .van-cell__value {
        color: #fff;
        text-align: left;
      }
    }
  }

  .dzfp-item /deep/ {
    background-color: #fff;
    margin: 10px;

    .dzfp-item--header {
      padding: 10px 16px;
    }

    .zwyf {
      background-color: #8c8c8c;
      font-size: 14px;
      color: #fff;
      margin-right: 5px;
    }

    .dzfp-item--content {
      padding: 10px 0;
      .van-cell {
        padding: 0 16px;
      }
    }

    .dzfp-item--actions {
      margin: 0 16px;
      padding: 10px 0;
      text-align: right;

      .van-button {
        margin-right: 10px;
        padding: 0 16px;
        border-radius: 3px;
        height: 28px;
        &:last-child {
          margin-right: 0;
        }
      }

      .divider {
        width: 1px;
        height: 16px;
        background-color: #ddd;
      }
    }
  }
</style>